import React, { Component } from "react";
import ReactHtmlParser from 'react-html-parser';

import Moment from "moment";
import es from "moment/locale/es";

Moment.locale("es", es);



class Search extends Component {

    string(text, equal) {
        let newText = text.toLowerCase();
        let number = newText.search(equal);
        let result = "..." + newText.slice((number - 120), (number + 120)) + "...";

        return (result)

    }
    strong(text, equal) {
        let newText = text.toLowerCase();
        let regex = new RegExp(equal, 'g');
        let result = newText.replace(regex, '<strong>' + equal + '</strong>');
        return (result)
    }

    news(item) {
        const searchNews = [];
        this.props.news.map((item) => {
            if (item.node.descripcion) {
                if (((item.node.descripcion).toLowerCase()).includes(this.props.location.state.search)) {
                    searchNews.push(item)


                }
            } else {
                if (((item.node.titulo).toLowerCase()).includes(this.props.location.state.search)) {
                    console.log(item)

                    searchNews.push(item)


                }
            }

        })
        if (searchNews.length > 0) {
            return (
                <ol className="search-results node-results">
                    {searchNews.map((item, index) => {
                        return (
                            <li key={index}>
                                <h3 className="title">
                                    <a href={`/${this.props.text.locate}/${this.props.text.nameUrl}/${(item.node.titulo).replace(/[^-A-Za-z0-9]+/g, '-').toLowerCase()}`} >{ReactHtmlParser(this.strong(item.node.titulo, this.props.location.state.search))}</a>
                                </h3>
                                <div className="search-snippet-info">
                                    <p className="search-snippet"
                                    > {item.node.descripcion && ReactHtmlParser(this.string(this.strong(item.node.descripcion, this.props.location.state.search), this.props.location.state.search))}</p>
                                    <p className="search-info">{this.props.text.title} - {Moment(item.node.fecha).format(
                                        'D/MM/YYYY h:mm:ss a'
                                    )}  </p>
                                </div>
                            </li>
                        )

                    })}
                </ol>
            )
        }

        else {
            return (<ul>
                <li>Verifique que las palabras estén bien escritas.</li>
                <li>No encierre la frase entre comillas si desea buscar por cada palabra individualmente. <em>fabulosa búsqueda</em> generalmente devolverá más resultados que <em>"fabulosa búsqueda"</em>.</li>
                <li>Considere relajar su búsqueda con <em>OR</em>. <em>fabulosa OR búsqueda</em> generalmente devolverá más resultados que <em>fabulosa búsqueda</em>.</li>
            </ul>)
        }
    }
    render() {


        return (

            <React.Fragment>
                {
                    this.props.location.state &&

                    <React.Fragment>
                        <h2>{this.props.location.state.search ? this.props.text.search : this.props.text.noSearch}</h2>
                        {this.props.location.state.search &&

                            this.news(this.props.news)

                        }
                    </React.Fragment>
                }
            </ React.Fragment>

        )
    }
}

export default Search
