import * as React from "react"
import appInsights from '../../../../AppInsights'; 
import Search from "../../../route/search"





// markup
const IndexPage = (props) => {
  return (
    <React.Fragment>
      <Search props={props} />
      </React.Fragment>
  )
}

export default IndexPage
